"use client";
import styled, { css } from "styled-components";
import { backgroundGradients } from "../../../constants/styles/gradients";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

interface ISCompBigCardWithImage {
  $backgroundGradient?: string;
}

const Bronze = css<ISCompBigCardWithImage>`
  background: ${({ $backgroundGradient }) =>
    $backgroundGradient
      ? backgroundGradients[$backgroundGradient]
      : "var(--blue-gradient)"};
  box-shadow: var(--big-elevation);
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  border-radius: 12px;
  padding: 52px 24px;
`;

const Silver = css<ISCompBigCardWithImage>``;

const Gold = css<ISCompBigCardWithImage>`
  padding: 100px 97px;
`;

const Platinum = css<ISCompBigCardWithImage>``;

const Diamond = css<ISCompBigCardWithImage>``;

export const SCompBigCardWithTextWrapper = styled.div<ISCompBigCardWithImage>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
