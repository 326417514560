import { ButtonInner } from "./ButtonInner";
import { IGenButton } from "../../utils/types_gen";

export type IGenButtonProps = Omit<IGenButton, "__typename"> &
  React.ButtonHTMLAttributes<HTMLButtonElement> &
  React.AnchorHTMLAttributes<HTMLAnchorElement> & {
    disableStyle?: boolean;
    activated?: boolean;
    linkTarget?: "_blank" | "_self";
    id?: string | undefined | null;
    as?: React.ElementType;
  };

export const Button: React.FC<IGenButtonProps> = (props) => (
  <ButtonInner {...props} />
);
