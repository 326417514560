"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import {
  CSSSubtitle01Med,
  CSSSubtitle01MedXLarge,
} from "../../../constants/styles/typographies/CSSTypographies";

const Bronze = css`
  ${CSSSubtitle01Med};
  color: var(--text-02);
  opacity: 0.75;
  margin-top: 16px;
`;

const Silver = css``;

const Gold = css``;

const Platinum = css``;

const Diamond = css`
  ${CSSSubtitle01MedXLarge}
`;

export const SCompBigCardWithTextBlockDescription = styled.div`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
/* $subtitle-01/$subtitle-01-med */
