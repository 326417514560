"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

const Bronze = css`
  display: flex;
  order: 1;
  position: relative;
  width: 100%;
  height: 265px;
  margin-bottom: 58px;
  align-self: center;
`;

const Silver = css``;

const Gold = css`
  width: 58.18%;
  height: 472px;
  margin-bottom: 0;
`;

const Platinum = css``;

const Diamond = css`
  flex: none;
  align-self: auto;
  width: 46.12%;
  height: 593px;
`;

export const SCompBigCardWithImageBlockImageWrapper = styled.div`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
