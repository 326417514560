"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

export interface ISSpacer {
  bronze?: string | number;
  gold?: string | number;
  platinum?: string | number;
}

const generateCss = (val) => {
  return val || val === 0
    ? `height: ${val}${isNaN(parseInt(val)) ? "" : "px"};`
    : "";
};

const Bronze = css<ISSpacer>`
  width: 100%;
  background: transparent;
  ${({ bronze }) => generateCss(bronze)};
`;

const Silver = css<ISSpacer>``;

const Gold = css<ISSpacer>`
  ${({ gold }) => generateCss(gold)};
`;

const Platinum = css<ISSpacer>``;

const Diamond = css<ISSpacer>`
  ${({ platinum }) => generateCss(platinum)};
`;

export const SSpacer = styled.div<ISSpacer>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
