"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import {
  CSSBody02,
  CssBody02XLarge,
} from "../../../constants/styles/typographies/CSSTypographies";

const Bronze = css`
  margin-top: 8px;
  color: var(--text-02);
  ${CSSBody02};
  opacity: 0.9;
`;

const Silver = css``;

const Gold = css`
  margin-top: 16px;
`;

const Platinum = css``;

const Diamond = css`
  ${CssBody02XLarge}
`;

export const SCompBigCardWithImageParagraphWrapper = styled.div`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
