"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

const Bronze = css`
  padding: 0 24px;
`;

const Silver = css``;

const Gold = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 100px;
  max-width: 50%;
`;

const Platinum = css`
  max-width: 42%;
`;

const Diamond = css`
  max-width: 33%;
  padding: 0;
`;

export const SCompBigCardWithImageLeftDescription = styled.div`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
