export const imageLoader = ({ src, ...rest }) => {
  if (src.endsWith(".svg")) {
    return src;
  }

  if (!rest.quality && !rest.width) {
    return src;
  }

  if (rest.quality && rest.width) {
    if (rest.quality === 99) {
      return `${src}?w=${rest.width}`;
    } else {
      return `${src}?w=${rest.width}&q=${rest.quality}`;
    }
  }

  return `${src}?w=${rest.width}`;
};
