"use client";
import Image, { ImageProps } from "next/image";
import { imageLoader } from "./imageLoader";
// this is just a little wrapper around next/image so we can easily replace it if need and change it behavior
export const NextImage = (
  props: Omit<ImageProps, "src"> & {
    xlinkHref?: string;
    src?: any;
    srcSet?: any;
  }
) => {
  if (!props.src) {
    return null;
  }

  if (!props.quality) {
    props = { ...props, quality: 99 };
  }

  return (
    <Image
      {...(props as ImageProps)}
      unoptimized={!!props.src?.endsWith(".svg")}
      loader={imageLoader}
    />
  );
};
