"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";
import {
  CSSHeading01,
  CSSHeading01Small,
  CSSHeading01XLarge,
} from "../../../constants/styles/typographies/CSSTypographies";

const Bronze = css`
  ${CSSHeading01Small};
  color: var(--text-10);
  text-align: center;

  p {
    display: inline;
  }

  strong {
    color: var(--text-02);
  }
`;

const Silver = css`
  p {
    display: block;
  }
`;

const Gold = css`
  ${CSSHeading01};
`;

const Platinum = css``;

const Diamond = css`
  ${CSSHeading01XLarge};
`;

export const SCompBigCardWithTextBlockHeader = styled.h2`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
