"use client";
import styled, { css } from "styled-components";
import { backgroundGradients } from "../../../constants/styles/gradients";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

interface ISCompBigCardWithImage {
  $backgroundGradient?: string;
}

const Bronze = css<ISCompBigCardWithImage>`
  background: ${({ $backgroundGradient }) =>
    $backgroundGradient
      ? backgroundGradients[$backgroundGradient]
      : "var(--blue-gradient)"};
  box-shadow: var(--big-elevation);
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;
  border-radius: 12px;
  padding: 51px 2px 24px 2px;
`;

const Silver = css<ISCompBigCardWithImage>``;

const Gold = css<ISCompBigCardWithImage>`
  flex-direction: row;
  gap: 65px;
  padding: 0;
`;

const Platinum = css<ISCompBigCardWithImage>``;

const Diamond = css<ISCompBigCardWithImage>`
  padding: 65px 49px 65px 149px;
`;

export const SCompBigCardWithImageWrapper = styled.div<ISCompBigCardWithImage>`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
